import React, {Fragment} from "react";
import {Formik, yupToFormErrors, useFormikContext, Form, Field} from "formik";
import * as Yup from "yup";
import {useState} from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import moment from "moment";
import "moment/locale/fr";

import "./FilterContainer.scss";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import User from "entities/User";

const FilterContainer = (props) => {
    const {
        type,
        periods,
        currentPeriodIndex,
        setCurrentPeriodIndex,
        managers,
        needPeriodUpdate,
        setNeedPeriodUpdate,
        needUserListUpdate,
        setNeedUserListUpdate,
        setPage,
    } = props;
    const me = useSelector((state) => state.user.me);

    const filterPersons = (inputValue, params) => {
        let tempList = [];
        if (type === "activity" && localStorage.getItem("userForCRAList")) {
            tempList = Object.values(
                JSON.parse(localStorage.getItem("userForCRAList"))
            );
        } else if (
            type === "absence" &&
            localStorage.getItem("userForAbsenceList")
        ) {
            tempList = Object.values(
                JSON.parse(localStorage.getItem("userForAbsenceList"))
            );
        } else if (
            type === "extra-activity" &&
            localStorage.getItem("userForExtraActivityList")
        ) {
            tempList = Object.values(JSON.parse(localStorage.getItem("userForExtraActivityList")))
        }

        let usersAvailable = [];
        tempList.map((element, index) => {
            usersAvailable.push(new User(element, false));
        });

        let result = usersAvailable
            .filter((user) => {
                return (
                    !user.matricule.startsWith("T") &&
                    (inputValue
                        ? user.lastname
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                        user.firstname
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                        user.matricule
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        : true) &&
                    (params.period
                        ? (moment
                                .parseZone(user.startAt)
                                .format("YYYY-MM-DD") <=
                            moment
                                .parseZone(params.period + "-01")
                                .endOf("month")
                                .format("YYYY-MM-DD") &&
                            moment
                                .parseZone(user.endAt)
                                .format("YYYY-MM-DD") >=
                            moment
                                .parseZone(params.period + "-01")
                                .format("YYYY-MM-DD")) ||
                        (moment
                                .parseZone(user.startAt)
                                .format("YYYY-MM-DD") <=
                            moment
                                .parseZone(params.period + "-01")
                                .endOf("month")
                                .format("YYYY-MM-DD") &&
                            !user.endAt)
                        : true) &&
                    (params.manager ? user.manager == params.manager : true)
                );
            })
            .sort((a, b) => {
                return a.lastname.localeCompare(b.lastname);
            });

        return result;
    };

    useEffect(() => {
        if (needUserListUpdate) {
            applyPersonFilter("", {
                period: periods[currentPeriodIndex].value,
                manager: me.isManager ? me.matricule : "",
            });
        }
    }, [needUserListUpdate]);

    const [users, setUsers] = useState(
        filterPersons("", {
            period: periods[currentPeriodIndex]?.value,
            manager: me.isManager && props.filters.manager ? me.matricule : "",
        })
    );

    const applyPersonFilter = (inputValue, params) => {
        let persons = filterPersons(inputValue, params);
        setUsers(persons);
    };

    const [open, setOpen] = useState(false);

    const schema = Yup.object();

    const FilterForm = (props) => {
        const {
            values,
            isSubmitting,
            setFieldValue,
            submitForm,
            // errors,
        } = useFormikContext();

        useEffect(() => {
            if (needPeriodUpdate) {
                setFieldValue("period", periods[currentPeriodIndex].value);
                setNeedPeriodUpdate(false);
                submitForm();
                applyPersonFilter(values.person, {
                    period: periods[currentPeriodIndex].value,
                });
            }
        }, [needPeriodUpdate]);

        const filterManagers = (inputValue) => {
            let filtered = managers.filter(
                (manager) =>
                    manager.isManager &&
                    manager.isVisible &&
                    !manager.matricule.startsWith("T") &&
                    (manager.lastname
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                        manager.firstname
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                        manager.matricule
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()))
            );

            if (me.isManager) {
                if (!filtered.find((element) => element.id === me.id)) {
                    let meArray = [me];
                    meArray = meArray.filter(
                        (user) =>
                            user.isManager &&
                            user.isActive &&
                            !user.matricule.startsWith("T") &&
                            (user.lastname
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase()) ||
                                user.firstname
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase()) ||
                                user.matricule
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase()))
                    );
                    if (meArray.length > 0) {
                        filtered.push(me);
                    }
                }
            }

            return filtered.sort((a, b) => {
                return a.lastname.localeCompare(b.lastname);
            });
        };

        // const loadPersons = (inputValue, callback) => {
        //     setTimeout(() => {
        //         callback(filterPersons(inputValue));
        //     }, 200);
        // };

        const loadManagers = (inputValue, callback) => {
            setTimeout(() => {
                callback(filterManagers(inputValue));
            }, 1);
        };

        const getManagerValue = () => {
            if (managers) {
                let filtered = managers.filter((user) => user.isManager);
                if (me.isManager) {
                    filtered.push(me);
                }
                return filtered.find(
                    (user) => user.matricule === values.manager
                );
            }
        };

        const AbsenceStates = () => {
            const checkStates = (state) => {
                if (values.states === ["all"]) {
                    setFieldValue("states", null);
                }
                if (!values.states.find((element) => element === state)) {
                    let temp;
                    if (values.states.indexOf("all") > -1) {
                        temp = [state];
                        setFieldValue("states", temp);
                    } else {
                        temp = [...values.states, state];
                        setFieldValue("states", temp);
                    }
                    if (
                        state === "all" ||
                        (temp.indexOf("submitted") > -1 &&
                            temp.indexOf("validated1") > -1 &&
                            temp.indexOf("validated2") > -1)
                    ) {
                        setFieldValue("states", ["all"]);
                    }
                } else {
                    let temp = [...values.states];
                    temp.splice(values.states.indexOf(state), 1);
                    setFieldValue("states", temp);
                }
            };

            return (
                <Fragment>
                    <div className="states-container-header">Etat Absences</div>
                    <div className="states-container-checkboxes">
                        <div className="states-row">
                            <Field
                                type="checkbox"
                                name="states"
                                value="all"
                                onChange={() => checkStates("all")}
                                id="states-all"
                            />
                            <label htmlFor="states-all">
                                Toutes les absences
                            </label>
                        </div>
                        <div className="states-row">
                            <Field
                                type="checkbox"
                                name="states"
                                value="submitted"
                                onChange={() => checkStates("submitted")}
                                id="states-submitted"
                            />
                            <label htmlFor="states-submitted">
                                Non validées par le manager
                            </label>
                        </div>
                        <div className="states-row">
                            <Field
                                type="checkbox"
                                name="states"
                                value="validated1"
                                onChange={() => checkStates("validated1")}
                                id="states-validated1"
                            />
                            <label htmlFor="states-validated1">
                                Validées par le manager
                            </label>
                        </div>
                        <div className="states-row">
                            <Field
                                type="checkbox"
                                name="states"
                                value="validated2"
                                onChange={() => checkStates("validated2")}
                                id="states-validated2"
                            />
                            <label htmlFor="states-validated2">
                                Validées par le service Paie
                            </label>
                        </div>
                    </div>
                </Fragment>
            );
        };

        const CRAStates = () => {
            const checkStates = (state) => {
                if (values.states === ["all"]) {
                    setFieldValue("states", null);
                }
                if (!values.states.find((element) => element === state)) {
                    let temp;
                    if (values.states.indexOf("all") > -1) {
                        temp = [state];
                        setFieldValue("states", temp);
                    } else {
                        temp = [...values.states, state];
                        setFieldValue("states", temp);
                    }
                    if (
                        state === "all" ||
                        (temp.indexOf("non_submitted") > -1 &&
                            temp.indexOf("submitted") > -1 &&
                            temp.indexOf("validated1") > -1 &&
                            temp.indexOf("validated2") > -1)
                    ) {
                        setFieldValue("states", ["all"]);
                    }
                } else {
                    let temp = [...values.states];
                    temp.splice(values.states.indexOf(state), 1);
                    setFieldValue("states", temp);
                }
            };

            return (
                <Fragment>
                    <div className="states-container-header">Etat CRA</div>
                    <div className="states-container-checkboxes">
                        <div className="states-row">
                            <Field
                                type="checkbox"
                                name="states"
                                value="all"
                                onChange={() => checkStates("all")}
                                id="states-all"
                            />
                            <label htmlFor="states-all">Tous</label>
                        </div>
                        <div className="states-row">
                            <Field
                                type="checkbox"
                                name="states"
                                value="non_submitted"
                                onChange={() => checkStates("non_submitted")}
                                id="states-non-submitted"
                            />
                            <label htmlFor="states-non-submitted">
                                CRAs non envoyés
                            </label>
                        </div>
                        <div className="states-row">
                            <Field
                                type="checkbox"
                                name="states"
                                value="submitted"
                                onChange={() => checkStates("submitted")}
                                id="states-submitted"
                            />
                            <label htmlFor="states-submitted">
                                CRAs non validés par le manager
                            </label>
                        </div>
                        <div className="states-row">
                            <Field
                                type="checkbox"
                                name="states"
                                value="validated1"
                                onChange={() => checkStates("validated1")}
                                id="states-validated1"
                            />
                            <label htmlFor="states-validated1">
                                CRAs validés par le manager
                            </label>
                        </div>
                        <div className="states-row">
                            <Field
                                type="checkbox"
                                name="states"
                                value="validated2"
                                onChange={() => checkStates("validated2")}
                                id="states-validated2"
                            />
                            <label htmlFor="states-validated2">
                                CRAs validés par le service GTA
                            </label>
                        </div>
                    </div>
                </Fragment>
            );
        };

        const PersonTypeFilter = () => {
            const checkPersonTypes = (personType) => {
                if (values.types === ["all"]) {
                    setFieldValue("types", null);
                }
                if (!values.types.find((element) => element === personType)) {
                    let temp;
                    if (values.types.indexOf("all") > -1) {
                        temp = [personType];
                        setFieldValue("types", temp);
                    } else {
                        temp = [...values.types, personType];
                        setFieldValue("types", temp);
                    }
                    if (
                        personType === "all" ||
                        (temp.indexOf("contractor") > -1 &&
                            temp.indexOf("internal") > -1 &&
                            temp.indexOf("consultant") > -1)
                    ) {
                        setFieldValue("types", ["all"]);
                    }
                } else {
                    let temp = [...values.types];
                    temp.splice(values.types.indexOf(personType), 1);
                    setFieldValue("types", temp);
                }
            };

            return (
                <div className="person-type-container">
                    <div className="person-type-container-header">
                        Statut collaborateur
                    </div>
                    <div className="person-type-container-checkboxes">
                        <div className="person-type-row">
                            <Field
                                type="checkbox"
                                name="types"
                                value="all"
                                onChange={() => checkPersonTypes("all")}
                                id="types-all"
                            />
                            <label htmlFor="types-all">Tous</label>
                        </div>

                        {me.hasRole("ROLE_ACTIVITY_SHOW_SALARIED") && (
                            <div className="person-type-row">
                                <Field
                                    type="checkbox"
                                    name="types"
                                    value="consultant"
                                    onChange={() =>
                                        checkPersonTypes("consultant")
                                    }
                                    id="types-consultant"
                                />
                                <label htmlFor="types-consultant">
                                    Consultant Salarié
                                </label>
                            </div>
                        )}

                        {me.hasRole("ROLE_ACTIVITY_SHOW_CONTRACTOR") &&
                            type === "activity" && (
                                <div className="person-type-row">
                                    <Field
                                        type="checkbox"
                                        name="types"
                                        value="contractor"
                                        onChange={() =>
                                            checkPersonTypes("contractor")
                                        }
                                        id="types-contractor"
                                    />
                                    <label htmlFor="types-contractor">
                                        Prestataire
                                    </label>
                                </div>
                            )}

                        {me.hasRole("ROLE_ACTIVITY_SHOW_INTERNAL") && (
                            <div className="person-type-row">
                                <Field
                                    type="checkbox"
                                    name="types"
                                    value="internal"
                                    onChange={() =>
                                        checkPersonTypes("internal")
                                    }
                                    id="types-internal"
                                />
                                <label htmlFor="types-internal">
                                    Structure Interne
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            );
        };

        const ParticularityFilters = () => {
            const checkParticularities = (particularity) => {
                if (values.particularities === ["all"]) {
                    setFieldValue("particularities", null);
                }
                if (
                    !values.particularities.find(
                        (element) => element === particularity
                    )
                ) {
                    let temp;
                    if (values.particularities.indexOf("all") > -1) {
                        temp = [particularity];
                        setFieldValue("particularities", temp);
                    } else {
                        temp = [...values.particularities, particularity];
                        setFieldValue("particularities", temp);
                    }
                    if (
                        particularity === "all" ||
                        (temp.indexOf("partial_time") > -1 &&
                            temp.indexOf("special_time") > -1 &&
                            temp.indexOf("special_time") > -1 &&
                            temp.indexOf("overtime") > -1 &&
                            temp.indexOf("pending_extra_activities_request") > -1
                        )
                    ) {
                        setFieldValue("particularities", ["all"]);
                    }
                } else {
                    let temp = [...values.particularities];
                    temp.splice(
                        values.particularities.indexOf(particularity),
                        1
                    );
                    setFieldValue("particularities", temp);
                }
            };

            return (
                <div className="particularities-container">
                    <div className="particularities-container-header">
                        Particularités
                    </div>
                    <div className="particularities-container-checkboxes">
                        <div className="particularities-row">
                            <Field
                                type="checkbox"
                                name="particularities"
                                value="all"
                                onChange={() => checkParticularities("all")}
                                id="particularities-all"
                            />
                            <label htmlFor="particularities-all">Toutes</label>
                        </div>
                        <div className="particularities-row">
                            <Field
                                type="checkbox"
                                name="particularities"
                                value="partial_time"
                                onChange={() =>
                                    checkParticularities("partial_time")
                                }
                                id="particularities-partial-time"
                            />
                            <label htmlFor="particularities-partial-time">
                                Temps partiel
                            </label>
                        </div>
                        <div className="particularities-row">
                            <Field
                                type="checkbox"
                                name="particularities"
                                value="remote_working"
                                onChange={() =>
                                    checkParticularities("remote_working")
                                }
                                id="particularities-remote-working"
                            />
                            <label htmlFor="particularities-remote-working">
                                Télétravail
                            </label>
                        </div>
                        <div className="particularities-row">
                            <Field
                                type="checkbox"
                                name="particularities"
                                value="special_time"
                                onChange={() =>
                                    checkParticularities("special_time")
                                }
                                id="particularities-special-time"
                            />
                            <label htmlFor="particularities-special-time">
                                Temps spécial
                            </label>
                        </div>
                        <div className="particularities-row">
                            <Field
                                type="checkbox"
                                name="particularities"
                                value="overtime"
                                onChange={() =>
                                    checkParticularities("overtime")
                                }
                                id="particularities-overtime"
                            />
                            <label htmlFor="particularities-overtime">
                                Activités complémentaires
                            </label>
                        </div>
                        <div className="particularities-row">
                            <Field
                                type="checkbox"
                                name="particularities"
                                value="pending_extra_activities_request"
                                onChange={() =>
                                    checkParticularities("pending_extra_activities_request")
                                }
                                id="particularities-pending-extra-activities-request"
                            />
                            <label htmlFor="particularities-pending-extra-activities-request">
                                Demande de saisie d'activités complémentaires en attente
                            </label>
                        </div>
                    </div>
                </div>
            );
        };

        const RequestStates = () => {
            const checkRequests = (state) => {
                if (!values.states.find((element) => element === state)) {
                    let temp;
                    // if (values.states.indexOf("all") > -1) {
                    //     temp = [state];
                    //     setFieldValue("states", temp);
                    // } else {
                    temp = [...values.states, state];
                    setFieldValue("states", temp);
                    // }
                    // if ((temp.indexOf("pending") > -1 &&
                    //         temp.indexOf("accepted") > -1 &&
                    //         temp.indexOf("refused") > -1)
                    // ) {
                    //     setFieldValue("states", ["pending"]);
                    // }
                } else {
                    let temp = [...values.states];
                    temp.splice(values.states.indexOf(state), 1);
                    setFieldValue("states", temp);
                }
            };

            return <div className={"requests-container"}>
                <div className={"requests-container-header"}>Etat Requête</div>
                <div className="requests-container-checkboxes">
                    <div className="requests-row">
                        <Field
                            type="checkbox"
                            name="states"
                            value="pending"
                            onChange={() => checkRequests("pending")}
                            id="requests-pending"
                        />
                        <label htmlFor="requests-pending">Requêtes en attente</label>
                    </div>
                    <div className="requests-row">
                        <Field
                            type="checkbox"
                            name="states"
                            value="accepted"
                            onChange={() => checkRequests("accepted")}
                            id="requests-accepted"
                        />
                        <label htmlFor="requests-validated">Requêtes validées</label>
                    </div>
                    <div className="requests-row">
                        <Field
                            type="checkbox"
                            name="states"
                            value="refused"
                            onChange={() => checkRequests("refused")}
                            id="requests-refused"
                        />
                        <label htmlFor="requests-refused">Requêtes refusées</label>
                    </div>
                </div>
            </div>
        }

        return (
            <Form noValidate>
                <Select
                    className="select-container"
                    classNamePrefix="select-container"
                    name="period"
                    value={
                        periods
                            ? periods.find(
                                (option) => option.value === values.period
                            )
                            : ""
                    }
                    options={periods}
                    isClearable={type === "absence"}
                    onChange={(option) => {
                        let periodValue = option ? option.value : "";
                        setFieldValue("period", periodValue);
                        let params = {
                            period: periodValue,
                            manager: values.manager,
                        };
                        applyPersonFilter("", params);
                    }}
                    placeholder="Période"
                />
                <Select
                    className="select-container"
                    classNamePrefix="select-container"
                    name="person"
                    value={
                        users.length > 0
                            ? users.find(
                                (user) => user.matricule === values.person
                            )
                            : []
                    }
                    options={users}
                    isClearable={true}
                    onChange={(option) =>
                        setFieldValue(
                            "person",
                            option ? option.matricule : null
                        )
                    }
                    getOptionValue={(option) => option.matricule}
                    placeholder="Collaborateur"
                    noOptionsMessage={() => "Aucun résultat"}
                    isOptionSelected={(selOpt) => {
                        return selOpt === values.matricule;
                    }}
                />
                <AsyncSelect
                    className="select-container"
                    classNamePrefix="select-container"
                    name="manager"
                    value={getManagerValue()}
                    loadOptions={loadManagers}
                    cacheOptions
                    defaultOptions
                    noOptionsMessage={() => "Aucun résultat"}
                    loadingMessage={() => "Chargement..."}
                    isClearable={true}
                    onChange={(option) => {
                        let managerValue = option ? option.matricule : null;
                        setFieldValue("manager", managerValue);
                        let params = {
                            manager: managerValue,
                            period: values.period,
                        };
                        applyPersonFilter(values.person, params);
                    }}
                    getOptionValue={(option) => option.matricule}
                    placeholder="Manager"
                />

                {(me.hasRole("ROLE_MANAGER") ||
                    me.hasRole("ROLE_FEE_MANAGER") ||
                    me.hasRole("ROLE_GTA") ||
                    me.hasRole("ROLE_PAY_MANAGER") ||
                    me.hasRole("ROLE_BILLING_SERVICE")) && <PersonTypeFilter/>}

                <div className="states-container">
                    {type === "absence" && <AbsenceStates/>}
                    {type === "activity" && <CRAStates/>}
                    {type === "extra-activity" && <RequestStates/>}
                </div>

                {type === "activity" && <ParticularityFilters/>}

                <button
                    className="next-btn"
                    type="submit"
                    disabled={isSubmitting}
                >
                    Filtrer
                </button>
            </Form>
        );
    };

    return (
        <div
            className={
                "filter-container" + (open ? " filter-container-open" : "")
            }
            onClick={() => {
                if (!open) {
                    setOpen(true);
                }
            }}
        >
            <div className="filter-container-header">
                <span className="filter-container-title">
                    Recherche Avancée
                </span>
                <span className="filter-container-icons">
                    <i className="fal fa-search filter-open"></i>
                    <i
                        onClick={() => setOpen(false)}
                        className="fal fa-times filter-close"
                    ></i>
                </span>
            </div>

            <Formik
                initialValues={props.filters}
                validate={(values, props) => {
                    return schema
                        .validate(values, {
                            abortEarly: false,
                            // context: { overtimeType, constraintCategory },
                        })
                        .then(() => {
                            return true;
                        })
                        .catch((err) => {
                            console.log(err);
                            return yupToFormErrors(err);
                        });
                }}
                onSubmit={(values, {setSubmitting}) => {
                    // let json = sanitizeForm(values);
                    let newPeriodIndex = periods.findIndex(
                        (element) => element.value === values.period
                    );
                    if (newPeriodIndex > -1) {
                        setCurrentPeriodIndex(newPeriodIndex);
                    }
                    setOpen(false);
                    setPage(1);
                    setTimeout(() => {
                        props.setFilters(values);
                        setSubmitting(false);
                    }, 1);
                }}
            >
                {({errors, isSubmitting}) => (
                    <FilterForm
                        resetFilters={props.resetFilters}
                        users={users}
                    />
                )}
            </Formik>
        </div>
    );
};

export default FilterContainer;
