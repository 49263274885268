import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    EntityRepository,
    DAY_REPOSITORY,
    HALFDAY_REPOSITORY,
    EVENT_REPOSITORY,
} from "store/EntityRepository";

import moment from "moment";
import "moment/locale/fr";

import {STEP2, STEP3} from "components/TimesheetContainer/Forms/Event/Edit";
import Step3Standard from "components/TimesheetContainer/Forms/Event/StepActions/Step3Standard";
import Step3Overtime from "components/TimesheetContainer/Forms/Event/StepActions/Step3Overtime";
import * as TimesheetActions from "actions/Timesheet";

const Step3Actions = (props) => {
    const me = useSelector((state) => state.user.me);
    const dispatch = useDispatch();
    const dayRepo = EntityRepository().getRepository(DAY_REPOSITORY);
    const halfDayRepo = EntityRepository().getRepository(HALFDAY_REPOSITORY);
    const eventRepo = EntityRepository().getRepository(EVENT_REPOSITORY);

    const RemoteWorkingCheckBox = (props) => {
        const eligible = props.eventCode && props.eventCode.eligibleToRemote
        if (!eligible) {
            setRemoteWorking(false)
        }

        const handleClick = () => {
            if (eligible) {
                setRemoteWorking(!remoteWorking)
            } else {
                dispatch(
                    TimesheetActions.notifyError(
                        "Télétravail non disponible pour cette activité"
                    )
                );
            }
        }

        return (
            <label
                htmlFor="remote-working"
                className={
                    (props.step !== STEP3 ? "hidden " : "") +
                    "remote-working-container " +
                    (!eligible ? "disabled" : "")
                }
                onClick={handleClick}
            >
                <i className="fas remote-working-icon fa-home-lg-alt"></i>
                Activité en télétravail
                <input
                    className="checkbox"
                    type="checkbox"
                    name="remote-working"
                    defaultChecked={remoteWorking}
                />
                <span className="checkmark" style={{marginRight: "20px"}}></span>
                {!eligible ? '(non disponible)' : ''}
            </label>
        );
    };

    const {
        startAt,
        endAt,
        eventToEdit,
        monthEntity,
        step,
        remoteWorking,
        setRemoteWorking,
        ignoreMisssion
    } = props;

    const boundingEvents = useSelector(
        (state) => state.timesheet.entities.boundingEvents
    );
    const previousEvent = boundingEvents.previous;
    const nextEvent = boundingEvents.next;

    const isAllowedToSwitch = (date, momentOfEvent) => {
        if (momentOfEvent === "start") {
            if (
                moment.parseZone(endAt).format("YYYY-MM-DD") ===
                moment.parseZone(startAt).format("YYYY-MM-DD")
            ) {
                if (props.selectedEndMoment === "am") {
                    return false;
                }
            }
            if (dayRepo.findForDateWithoutHook(date)) {
                let halfdayId = dayRepo.findForDateWithoutHook(date).am;
                if (halfdayId) {
                    let halfdayEntity = halfDayRepo.findWithoutHook(halfdayId);
                    if (
                        monthEntity.specialTime &&
                        halfdayEntity.workingState !== "worked"
                    ) {
                    } else if (eventToEdit) {
                        if (halfdayEntity.event !== eventToEdit.id) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            }
        } else if (momentOfEvent === "end") {
            if (
                moment.parseZone(endAt).format("YYYY-MM-DD") ===
                moment.parseZone(startAt).format("YYYY-MM-DD")
            ) {
                if (props.selectedStartMoment === "pm") {
                    return false;
                }
            }
            if (dayRepo.findForDateWithoutHook(date)) {
                let halfdayId = dayRepo.findForDateWithoutHook(date).pm;
                if (halfdayId) {
                    let halfdayEntity = halfDayRepo.findWithoutHook(halfdayId);
                    if (
                        monthEntity.specialTime &&
                        halfdayEntity.workingState !== "worked"
                    ) {
                        return true;
                    } else if (eventToEdit) {
                        if (halfdayEntity.event !== eventToEdit.id) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            }
        }

        return true;
    };
    const [allowedToSwitchStart, setAllowedToSwitchStart] = useState(
        isAllowedToSwitch(startAt, "start")
    );
    const [allowedToSwitchEnd, setAllowedToSwitchEnd] = useState(
        isAllowedToSwitch(endAt, "end")
    );

    useEffect(() => {
        setAllowedToSwitchStart(isAllowedToSwitch(startAt, "start"));
        setAllowedToSwitchEnd(isAllowedToSwitch(endAt, "end"));
    }, [startAt, props.selectedStartMoment, endAt, props.selectedEndMoment]);

    useEffect(() => {
        if (dayRepo.findForDateWithoutHook(startAt)) {
            let halfdayId = dayRepo.findForDateWithoutHook(startAt).am;
            if (halfdayId && eventToEdit) {
                if (
                    halfDayRepo.findWithoutHook(halfdayId).event !==
                    eventToEdit.id
                ) {
                    props.setSelectedStartMoment("pm");
                }
            }
        }
    }, [startAt]);

    useEffect(() => {
        if (dayRepo.findForDateWithoutHook(endAt)) {
            let halfdayId = dayRepo.findForDateWithoutHook(endAt).pm;
            if (halfdayId && eventToEdit) {
                if (
                    halfDayRepo.findWithoutHook(halfdayId).event !==
                    eventToEdit.id
                ) {
                    props.setSelectedEndMoment("am");
                }
            }
        }
    }, [endAt]);

    const formattedDate = (date, format) => {
        if (format) {
            return moment.parseZone(date, format).format("ddd D MMMM");
        }

        return moment.parseZone(date).format("ddd D MMMM");
    };

    const isAllowed = (date) => {
        if (!dayRepo.findForDateWithoutHook(date)) {
            return false;
        } else {
            if (!dayRepo.findForDateWithoutHook(date).worked) {
                return false;
            }

            const day = moment.parseZone(date).day();
            let minAt = moment
                .parseZone(eventToEdit.startAt)
                .startOf("month")
                .format("YYYY-MM-DD");
            let maxAt = moment
                .parseZone(eventToEdit.startAt)
                .endOf("month")
                .format("YYYY-MM-DD");
            let lowerLimited,
                upperLimited = false;
            if (previousEvent) {
                if (
                    moment.parseZone(date) <
                    moment.parseZone(previousEvent.endAt) ||
                    (moment.parseZone(date).format("YYYY-MM-DD") ===
                        moment
                            .parseZone(previousEvent.endAt)
                            .format("YYYY-MM-DD") &&
                        previousEvent.endMoment === "pm")
                ) {
                    lowerLimited = true;
                }
            }

            if (nextEvent) {
                if (
                    moment.parseZone(date) >
                    moment.parseZone(nextEvent.startAt) ||
                    (moment.parseZone(date).format("YYYY-MM-DD") ===
                        moment
                            .parseZone(nextEvent.startAt)
                            .format("YYYY-MM-DD") &&
                        nextEvent.startMoment === "am")
                ) {
                    upperLimited = true;
                }
            }

            return (
                day !== 0 &&
                day !== 6 &&
                !lowerLimited &&
                !upperLimited &&
                moment.parseZone(date).format("YYYY-MM-DD") >= minAt &&
                moment.parseZone(date).format("YYYY-MM-DD") <= maxAt
            );
        }
    };

    return (
        <div
            className={
                (props.step !== STEP3 ? "hidden " : "") +
                (props.selectedEventClass === "hours-not-worked"
                    ? "step3-overtime "
                    : "step3-standard ") +
                "step3-actions event-code-buttons"
            }
        >
            {props.startAt !== props.endAt && (props.modality === "1" || props.modality === "2") &&
                <div className={"step3-daily-hours"}>
                    Temps de travail quotidien <span className={"bold"}>{props.modality == "2" ? '7h42' : '7h'}</span>
                </div>
            }
            {props.selectedEventClass === "hours-not-worked" ? (
                <Step3Overtime
                    startAt={startAt}
                    endAt={endAt}
                    isAllowed={isAllowed}
                    allowedToSwitchStart={allowedToSwitchStart}
                    allowedToSwitchEnd={allowedToSwitchEnd}
                    isEdit={props.isEdit}
                    eventToEdit={props.eventToEdit}
                    step={props.step}
                    nextStep={props.nextStep}
                    selectedStartMoment={props.selectedStartMoment}
                    selectedEndMoment={props.selectedEndMoment}
                    setSelectedStartMoment={props.setSelectedStartMoment}
                    setSelectedEndMoment={props.setSelectedEndMoment}
                    selectedEventClass={props.selectedEventClass}
                    selectedEventCode={props.selectedEventCode}
                    startLocked={props.startLocked}
                    endLocked={props.endLocked}
                    setStartAt={props.setStartAt}
                    setEndAt={props.setEndAt}
                    constraintCategory={props.constraintCategory}
                    selectedOvertimeCode={props.selectedOvertimeCode}
                    setOvertimeFormValues={props.setOvertimeFormValues}
                    ignoreMisssion={ignoreMisssion}
                />
            ) : (
                <Step3Standard
                    startAt={startAt}
                    endAt={endAt}
                    isAllowed={isAllowed}
                    allowedToSwitchStart={allowedToSwitchStart}
                    allowedToSwitchEnd={allowedToSwitchEnd}
                    isEdit={props.isEdit}
                    eventToEdit={props.eventToEdit}
                    step={props.step}
                    nextStep={props.nextStep}
                    selectedStartMoment={props.selectedStartMoment}
                    selectedEndMoment={props.selectedEndMoment}
                    setSelectedStartMoment={props.setSelectedStartMoment}
                    setSelectedEndMoment={props.setSelectedEndMoment}
                    selectedEventClass={props.selectedEventClass}
                    selectedEventCode={props.selectedEventCode}
                    startLocked={props.startLocked}
                    endLocked={props.endLocked}
                    setStartAt={props.setStartAt}
                    setEndAt={props.setEndAt}
                    hasEnoughVacations={props.hasEnoughVacations}
                >
                    {props.selectedEventClass === "activity" && me.type !== "contractor" && (
                        <RemoteWorkingCheckBox step={step} eventCode={props.selectedEventCode}/>
                    )}
                </Step3Standard>
            )}
        </div>
    );
};

export default Step3Actions;
