import React, {useState} from "react";
import {useDispatch} from "react-redux";

import Lightbox from "components/common/Lightbox";
import CheckBox from "components/TimesheetContainer/Calendar/CheckBox";

import {monthInLetter, isEmpty} from "utils/Utils";
import {changeOverlayContent} from "events/OverlayEvents";
import * as TimesheetActions from "actions/Timesheet";
import {setLoading} from "actions/Common";

import "./ConfirmSendMonth.scss";
import SpecificCRAContainer from "./SpecificCRAContainer";
import Step1SendMonth from "./Step1SendMonth";
import Step2SendMonth from "./Step2SendMonth";

const ConfirmSendMonth = (props) => {
    const dispatch = useDispatch();
    const {
        month,
        personType,
        year,
        hasEvents,
        surveySent,
        hasExtraActivityPaymentChoice,
        extraActivities,
        extraActivityWeeks,
        overtimes,
        previousMonth
    } = props;
    const [certified, setCertified] = useState(false);
    const [special, setSpecial] = useState(false);
    const [comment, setComment] = useState("");
    const [extraActivityPayment, setExtraActivityPayment] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [files, setFiles] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [noPrestationChecked, setNoPrestationChecked] = useState(false);
    const [touched, setTouched] = useState(false);
    const [step, setStep] = useState(1)
    const [satisfaction, setSatisfaction] = useState(undefined);

    const validateForm = () => {
        let isValid = true;
        if (special) {
            if (isEmpty(files)) {
                dispatch(
                    TimesheetActions.notifyError(
                        "Veuillez fournir au moins 1 fichier de CRA spécifique"
                    )
                );
                return false;
            }
            if (!certified) {
                dispatch(
                    TimesheetActions.notifyError(
                        "Vous devez certifier la conformité du CRA spécifique"
                    )
                );
                return false;
            }
        }

        if (personType === "contractor" && !hasEvents) {
            if (!noPrestationChecked) {
                dispatch(
                    TimesheetActions.notifyError(
                        "Veuillez confirmer que vous n'avez effectué aucune prestation ce mois-ci"
                    )
                );
                return false;
            }
        }

        if (personType === "consultant" && !surveySent && satisfaction === undefined && step === 2) {
            dispatch(
                TimesheetActions.notifyError(
                    "Veuillez remplir l'enquête de satisfaction"
                )
            );
            return false;
        }

        return isValid
    }

    const handleSubmit = () => {
        let isValid = validateForm()
        if (!isValid) {
            return;
        }

        if (!submitting) {
            setSubmitting(true);
            let json = {
                certified: certified,
                special: special,
                comment: comment,
            };
            if (personType === "consultant" && !surveySent) {
                json.satisfaction = satisfaction;
            }
            if (noPrestationChecked) {
                json.no_prestation = true;
            }
            if (hasExtraActivityPaymentChoice()) {
                json.extra_activity_payment = extraActivityPayment;
            }
            let filesArray = [];
            files.map((element) => {
                let file = {
                    file: {file: element.path + "||" + element.base64},
                };
                filesArray.push(file);
            });
            json.files = filesArray;
            dispatch(setLoading(true));
            return dispatch(
                TimesheetActions.postMonth(month, year, JSON.stringify(json))
            ).then(() => {
                changeOverlayContent(null);
                setSubmitting(false);
                return Promise.resolve(
                    dispatch(TimesheetActions.clearDaysSelection())
                ).then(() => {
                    dispatch(setLoading(false));
                    Promise.all([
                        dispatch(
                            TimesheetActions.fetchEntireMonth(month, year)
                        ),
                        dispatch(
                            TimesheetActions.notifySuccess(
                                "Votre déclaration a été envoyée"
                            )
                        ),
                    ]);
                });
            });
        }
    };

    const handleClick = () => {
        if (personType !== "consultant") {
            if (hasExtraActivityPaymentChoice() && !extraActivityPayment && personType !== "contractor") {
                dispatch(
                    TimesheetActions.notifyError(
                        "Veuillez choisir le type de paiement"
                    )
                );
            } else {
                handleSubmit();
            }
        } else if (step === 1 && surveySent) {
            if (hasExtraActivityPaymentChoice() && !extraActivityPayment) {
                dispatch(
                    TimesheetActions.notifyError(
                        "Veuillez choisir le type de paiement"
                    )
                );
            } else {
                handleSubmit();
            }
        } else if (step === 1) {
            if (hasExtraActivityPaymentChoice() && !extraActivityPayment) {
                dispatch(
                    TimesheetActions.notifyError(
                        "Veuillez choisir le type de paiement"
                    )
                );
            } else {
                if (validateForm()) {
                    setStep(2);
                }
            }
        } else {
            handleSubmit();
        }
    }

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const changeExtraActivityPayment = (paymentType) => {
        setExtraActivityPayment(paymentType);
    }


    return (
        <Lightbox className="lb-w1200">
            <div className="lb-confirm-send">
                {step === 1 ?
                    <Step1SendMonth
                        handleCommentChange={handleCommentChange}
                        comment={comment}
                        files={files}
                        setFiles={setFiles}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        personType={personType}
                        special={special}
                        setSpecial={setSpecial}
                        certified={certified}
                        setCertified={setCertified}
                        touched={touched}
                        setTouched={setTouched}
                        noPrestationChecked={noPrestationChecked}
                        setNoPrestationChecked={setNoPrestationChecked}
                        month={month}
                        hasEvents={hasEvents}
                        hasExtraActivityPaymentChoice={hasExtraActivityPaymentChoice}
                        extraActivities={extraActivities}
                        extraActivityWeeks={extraActivityWeeks}
                        extraActivityPayment={extraActivityPayment}
                        changeExtraActivityPayment={changeExtraActivityPayment}
                        overtimes={overtimes}
                        previousMonth={previousMonth}
                    /> :
                    <Step2SendMonth
                        satisfaction={satisfaction}
                        setSatisfaction={setSatisfaction}
                    />
                }

                <div className="lb-confirm-send-actions">
                    <div
                        onClick={() => changeOverlayContent(null)}
                        className="lb-confirm-send-cancel"
                    >
                        Annuler
                    </div>
                    <div
                        onClick={handleClick}
                        className="lb-confirm-send-confirm"
                    >
                        Envoyer mon
                        CRA {personType === "consultant" ? ((step === 1 && !surveySent) ? ' 1/2' : (step === 2 ? ' 2/2' : '')) : ''}
                    </div>
                </div>
            </div>
        </Lightbox>
    );
};

export default ConfirmSendMonth;
