import React, {useState, useEffect, Fragment} from "react";
import "./ExtraActivitiesContainer.scss"
import moment from "moment";
import "moment/locale/fr";
import useOvertime, {VALIDATION} from "../../../hooks/useOvertime";
import * as ValidationActions from "actions/Validation";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import ExtraActivityConfig from "../../../entities/ExtraActivityConfig";
import * as TimesheetActions from "actions/Timesheet";

const ExtraActivitiesContainer = (props) => {
        const {extraActivitiesWeeks, me, loadList, month} = props;
        const dispatch = useDispatch()
        const {getOvertimeCode, getMission} = useOvertime(VALIDATION)

        useEffect(() => {
            // loadList()
        }, [])

        const getDuration = (start, end, asString) => {
            let duration = 0;
            let durationString;
            let startTime = moment.parseZone(start, "HH:mm");
            let endTime = moment.parseZone(end, "HH:mm");
            let addTime;
            if (endTime.format("HH:mm") === "00:00") {
                addTime = 24 * 60 * 60 * 1000 + endTime.diff(startTime);
            } else {
                addTime = endTime.diff(startTime);
            }
            if (!isNaN(addTime)) {
                duration += addTime;
            }

            if (!asString) {
                return duration;
            }

            let hours = parseInt(moment.duration(duration).asHours());
            let minutes = parseInt(moment.duration(duration).asMinutes()) % 60;

            if (!isNaN(hours) && !isNaN(minutes) && duration > 0) {
                if (minutes < 10) {
                    minutes = "0" + minutes;
                }

                durationString = hours + ":" + minutes;
            } else {
                durationString = "0:00";
            }

            return durationString;
        }

        const ExtraActivityLine = (props) => {
            const {extraActivity, type, date, mode, dayConfig, setWeekValues, weekValues, dayIndex, hourIndex} = props;
            // const overtimeCodes = useSelector((state) => state.validation.craOvertimeCodes)
            // const [overtimeCode, setOvertimeCode] = useState(undefined)
            const overtimeCode = getOvertimeCode(extraActivity.code)
            const mission = getMission(extraActivity.code)
            const overtimeLabel = overtimeCode ? overtimeCode.description : (type === "complementaries" ? "Activité complémentaire" : "Intervention en astreinte")
            const missionLabel = mission ? mission.description : "-"

            const allowedMissionCodes = type === "complementaries" ? dayConfig.event_codes : dayConfig.event_code
            const [allowedMissionCodesForSelect, setAllowedMissionCodesForSelect] = useState([])

            const allowedOvertimeCodes = dayConfig.overtime_codes
            const [allowedOvertimeCodesForSelect, setAllowedOvertimeCodesForSelect] = useState([])

            const [selectedOvertime, setSelectedOvertime] = useState()

            // useEffect(() => {
            //     console.log("Extra activitiy code : " + extraActivity.code);
            //     setOvertimeCode(overtimeCodes.find(overtimeCode => overtimeCode.id === extraActivity.code));
            // }, [extraActivity, overtimeCodes])

            useEffect(() => {
                console.log(overtimeCode);
            }, [overtimeCode])

            useEffect(() => {
                if (overtimeCode) {
                    setSelectedOvertime(overtimeCode.id)
                }
            }, [overtimeCode])

            useEffect(() => {
                let tempArray = []
                if (allowedMissionCodes) {
                    if (type === "complementaries") {
                        allowedMissionCodes.map((code) => {
                            tempArray.push({
                                value: code.id,
                                label: code.description,
                            });
                        })
                    } else {
                        tempArray.push({
                            value: allowedMissionCodes.id,
                            label: allowedMissionCodes.description,
                        });
                    }
                    setAllowedMissionCodesForSelect(tempArray)
                }
            }, [allowedMissionCodes])

            useEffect(() => {
                let tempArray = []

                if (allowedOvertimeCodes) {
                    allowedOvertimeCodes.map((code) => {
                        tempArray.push({
                            value: code.id,
                            label: code.description,
                        });
                    })
                    setAllowedOvertimeCodesForSelect(tempArray)
                }
            }, [allowedOvertimeCodes])

            const handleChange = (overtime) => {
                let copy = {...weekValues}
                if (type === "complementaries") {
                    copy.complementaries[dayIndex].hours[hourIndex].code = overtime.value
                    setWeekValues(copy)
                } else if (type === 'constraints') {
                    copy.constraints[dayIndex].hours[hourIndex].code = overtime.value
                    setWeekValues(copy)
                }
                setSelectedOvertime(overtime.value)
            }

            return <tr className="overtime-line">
                <td className={`extra-activities-validation-type ${type}-type`}
                    title={type === "complementaries" ? "Activité complémentaire" : "Intervention en astreinte"}>{type === "complementaries" ? "AC" : "IA"}</td>

                <td className={"extra-activity-mission" + (mode === "edit" ? " extra-activity-mission-no-overflow" : "")}
                    title={missionLabel}>
                    {(mode === "view" || !mission) ?
                        <div>{missionLabel}</div> :
                        <Select className="overtime-edit-type"
                                classNamePrefix="overtime-edit-type"
                                name="overtime-code"
                                value={allowedMissionCodesForSelect ? allowedMissionCodesForSelect.find(
                                    (option) => {
                                        return (
                                            option.value ===
                                            mission.id
                                        );
                                    }
                                ) : ''}
                                isOptionSelected={(selOpt) => {
                                    return selOpt === mission.id;
                                }}
                                options={allowedMissionCodesForSelect}
                                placeholder="Chargement..."
                        />}
                </td>
                <td className={"extra-activity-label" + (mode === "edit" ? " extra-activity-label-no-overflow" : "")}
                    title={overtimeLabel}>
                    {(mode === "view" || !overtimeCode) ?
                        <div>{overtimeLabel}</div> :
                        <Select className="overtime-edit-type"
                                classNamePrefix="overtime-edit-type"
                                name="overtime-code"
                                value={allowedOvertimeCodesForSelect ? allowedOvertimeCodesForSelect.find(
                                    (option) => {
                                        return (
                                            option.value ===
                                            selectedOvertime
                                        );
                                    }
                                ) : ''}
                                isOptionSelected={(selOpt) => {
                                    return selOpt === selectedOvertime;
                                }}
                                options={allowedOvertimeCodesForSelect}
                                onChange={(option) =>
                                    handleChange(option)
                                }
                                placeholder="Chargement..."
                        />}
                </td>
                <td className="overtime-date left-align">{moment.parseZone(date).format("DD/MM/YYYY")}</td>
                <td className="overtime-start-at left-align">{extraActivity.start}</td>
                <td className="overtime-end-at left-align">{extraActivity.end}</td>
                <td className="overtime-hour-amount left-align">{getDuration(extraActivity.start, extraActivity.end, true)}</td>
                <td className="overtime-spacer"></td>
            </tr>
        };

        const ExtraActivitiesWeek = (props) => {
            const {week, month} = props;

            const [config, setConfig] = useState(undefined)
            const [mode, setMode] = useState(props.mode)

            const initValues = () => {
                return {
                    comment: week.comment,
                    complementaries: week.complementaries,
                    constraints: week.constraints
                }
            }
            const [weekValues, setWeekValues] = useState(initValues())
            // const [initialValues, setInitialValues] = useState(initValues())

            // useEffect(() => {
            //      setWeekValues(initialValues)
            // }, [initialValues])

            const isWeekEditable = () => {
                let complementariesEditable, constraintsEditable = false;
                if (week.complementaries.length > 0) {
                    week.complementaries.map((day) => {
                        complementariesEditable = day.hours.some((hour) => {
                            return hour.code
                        })
                    })
                }

                if (week.constraints.length > 0) {
                    week.constraints.map((day) => {
                        constraintsEditable = day.hours.some((hour) => {
                            return hour.code
                        })
                    })
                }

                return (complementariesEditable || constraintsEditable)
            }

            const totalDurationForWeek = (type) => {
                let duration = 0
                let durationString;

                if (type === "constraints") {
                    week.constraints.map((day) => {
                        day.hours.map((extraActivity) => {
                            duration += getDuration(extraActivity.start, extraActivity.end, false)
                        })
                    })
                } else if (type === "complementaries") {
                    week.complementaries.map((day) => {
                        day.hours.map((extraActivity) => {
                            duration += getDuration(extraActivity.start, extraActivity.end, false)
                        })
                    })
                }

                let hours = parseInt(moment.duration(duration).asHours());
                let minutes = parseInt(moment.duration(duration).asMinutes()) % 60;

                if (!isNaN(hours) && !isNaN(minutes) && duration > 0) {
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }

                    durationString = hours + ":" + minutes;
                } else {
                    durationString = "0:00";
                }

                return durationString;
            }

            const switchEdit = (action) => {
                if (action === "edit") {
                    setMode("edit");
                    dispatch(ValidationActions.fetchExtraActivitiesConfigForValidation(week.id)).then((response) => {
                        let extraActivities = response.response.entities.extraActivitiesConfigForValidation['config'];
                        setConfig(new ExtraActivityConfig(extraActivities))
                    })
                } else {
                    setMode("view")
                }
            }

            const handleSubmit = () => {
                let copy = {...weekValues}
                const complementaries = []
                copy.complementaries.map((day, index) => {
                    let dayCopy;
                    dayCopy = {date: day.date, hours: []}

                    day.hours.map((hour) => {
                        if (hour.start !== '') {
                            // let hourCopy;
                            // if (hour.constructor.name === "ExtraActivityHour") {
                                let hourCopy = {start: hour.start, end: hour.end, code: hour.code}
                            // } else {
                            //     hourCopy = hour;
                            // }
                            dayCopy.hours.push(hourCopy)
                        }
                    })

                    if (dayCopy.hours.length > 0) {
                        complementaries.push(dayCopy)
                    }
                })

                const constraints = []
                copy.constraints.map((day, index) => {
                    let dayCopy = {date: day.date, hours: []}
                    day.hours.map((hour) => {
                        if (hour.start !== '') {
                            // let hourCopy;
                            // if (hour.constructor.name === "ExtraActivityHour") {
                                let hourCopy = {start: hour.start, end: hour.end, code: hour.code}
                            // } else {
                            //     hourCopy = hour;
                            // }
                            dayCopy.hours.push(hourCopy)
                        }
                    })

                    if (dayCopy.hours.length > 0) {
                        constraints.push(dayCopy)
                    }
                })

                let body = {
                    comment: copy.comment,
                    complementaries: complementaries,
                    constraints: constraints,
                }

                dispatch(ValidationActions.updateExtraActivitiesForValidation(week.id, JSON.stringify(body))).then(() => {
                    loadList();
                    dispatch(TimesheetActions.notifySuccess("Modification effectuée"));
                })
            }

            const closeForm = () => {
                switchEdit("view");
                loadList();
            }

            return <div>
                <div className="extra-activity-week-header">Semaine {week.week}
                    {isWeekEditable() && (mode !== "edit" ? me.hasRole("ROLE_ACTIVITY_OVERTIME_EDIT_TYPE") && month.submitted && !month.validated2 && (
                        <div className="extra-activity-week-actions">
                            <i className="far fa-edit edit-extra-activities-icon edit-extra-activities-btn" onClick={() => switchEdit("edit")}></i>
                        </div>
                    ) : <div className="extra-activity-week-actions">
                        <i className="far fa-times edit-extra-activities-icon close-edit-extra-activities"
                           onClick={closeForm}></i>
                        <i className="fas fa-check-square edit-extra-activities-icon validate-edit-extra-activities"
                           onClick={handleSubmit}></i>
                    </div>)}

                </div>
                <table className="overtime-table extra-activity-container">
                    <thead className="overtime-table-header">
                    <tr>
                        <td className="header header-type">Type</td>
                        <td className="header header-mission">Mission</td>
                        <td className="header header-extra-activity-label">Libellé</td>
                        <td className="header header-date">Date</td>
                        <td className="header header-start-at left-align">H début</td>
                        <td className="header header-end-at left-align">H fin</td>
                        <td className="header header-hour-amount left-align">Nb h.</td>
                        <td className="header header-extra-activity-spacer"></td>
                    </tr>
                    </thead>
                    <tbody>
                    {week.complementaries.map((day, index) => {
                        return day.hours.map((extraActivity, key) => {
                            return <ExtraActivityLine key={'comp-' + index + '-' + key}
                                                      extraActivity={extraActivity}
                                                      type="complementaries"
                                                      date={day.date}
                                                      mode={mode}
                                                      dayConfig={config ? config.complementaries.find((dayConfig) => day.date === dayConfig.date) : {}}
                                                      setWeekValues={setWeekValues}
                                                      weekValues={weekValues}
                                                      dayIndex={index}
                                                      hourIndex={key}
                            />
                        })
                    })}
                    {week.complementaries.length > 0 &&
                        <tr>
                            <td colSpan={3}>&nbsp;</td>
                            <td colSpan={4} className={"extra-activity-container-total complementaries-type"}>
                                <div>TOTAL
                                    Activités complémentaires : <span
                                        className="extra-activity-total-line-ml-35 colorless">{totalDurationForWeek("complementaries")}</span>
                                </div>
                            </td>
                            <td colSpan={1}>&nbsp;</td>
                        </tr>}

                    {week.constraints.map((day, index) => {
                        return day.hours.map((extraActivity, key) => {
                            return <ExtraActivityLine key={'constr-' + index + '-' + key}
                                                      extraActivity={extraActivity}
                                                      type="constraints"
                                                      date={day.date}
                                                      mode={mode}
                                                      dayConfig={config ? config.constraints.find((dayConfig) => day.date === dayConfig.date) : {}}
                                                      setWeekValues={setWeekValues}
                                                      weekValues={weekValues}
                                                      dayIndex={index}
                                                      hourIndex={key}
                            />
                        })
                    })}
                    {week.constraints.length > 0 && <tr>
                        <td colSpan={3}>&nbsp;</td>
                        <td colSpan={4} className={"extra-activity-container-total constraints-type"}>
                            <div>TOTAL Interventions en
                                astreinte : <span
                                    className="extra-activity-total-line-ml-37 colorless">{totalDurationForWeek("constraints")}</span>
                            </div>
                        </td>
                        <td colSpan={1}>&nbsp;</td>
                    </tr>}
                    {week.comment && <tr>
                        {/*<td colSpan={3}>&nbsp;</td>*/}
                        <td className="extra-activity-week-comment-container" colSpan={8}>
                            <div className="extra-activity-week-comment-label">Commentaire</div>
                            <div className="extra-activity-week-comment">{week.comment}</div>
                        </td>
                    </tr>}
                    </tbody>
                </table>
            </div>
        }

        return (
            <div className="intervention-container">
                <div className="validate-extra-activity-container-title">
                    Activités Complémentaires
                </div>
                {extraActivitiesWeeks.sort((a, b) => {
                    return a.week - b.week
                }).map((element, key) => {
                    return element.duration > 0 && <ExtraActivitiesWeek week={element} key={key} mode={"view"} month={month} />
                })}
            </div>
        );
    }
;

export default ExtraActivitiesContainer
