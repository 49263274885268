import React, {Fragment} from "react";
import {Redirect, Route, Switch} from "react-router";
import Header from "components/common/Header/Header";
import HomepageContainer from "components/HomepageContainer/HomepageContainer";
import TimesheetContainer from "components/TimesheetContainer/TimesheetContainer";
import ContactContainer from "components/ContactContainer/ContactContainer";
import NewsContainer from "components/NewsContainer/NewsContainer";
import GDPRContainer from "components/GDPRContainer/GDPRContainer";
import RunAsContainer from "components/RunAsContainer/RunAsContainer";
import MyAccountContainer from "components/MyAccountContainer/MyAccountContainer";
import Error from "components/common/Error";
import {getContactRoutes} from "config/Routes";
import TutorialContainer from "./TutorialContainer/TutorialContainer";
import DocumentContainer from "./DocumentContainer/DocumentContainer";
import "moment/locale/fr";

const FrontendRoutes = (props) => {
    const {me} = props;

    return (
        <Fragment>
            <Header/>
            <Switch>
                <Route exact path="/" component={() => <HomepageContainer/>}/>
                <Route
                    path="/timesheet/:month?/:year?"
                    component={() => <TimesheetContainer/>}
                />
                <Route
                    strict
                    exact
                    path={getContactRoutes(props.me)}
                    component={() => <ContactContainer/>}
                />
                {me.type !== "internal" && (
                    <Route
                        exact
                        path="/account"
                        component={() => <MyAccountContainer/>}
                    />
                )}

                <Route
                    exact
                    path="/actus/:postId?"
                    component={() => <NewsContainer/>}
                />
                <Route exact path="/rgpd" component={() => <GDPRContainer/>}/>
                <Route
                    exact
                    path="/tutorial"
                    component={() => <TutorialContainer/>}
                />

                {me.id && me.type !== "contractor" && (
                    <Route
                        exact
                        path={[
                            "/documents",
                            "/documents/:type",
                            "/documents/:type/:id",
                        ]}
                        component={() => <DocumentContainer/>}
                    />
                )}
                {/* <Route
                    exact
                    path="/documents/syndicats"
                    component={() => <UnionHome />}
                />
                <Route
                    exact
                    path="/documents/syndicats/:id"
                    component={() => <UnionDocuments />}
                /> */}
                {me.hasRole("ROLE_ALLOWED_TO_SWITCH") && (
                    <Route
                        exact
                        path="/runas"
                        component={() => <RunAsContainer/>}
                    />
                )}

                <Route exact path="/login">
                    <Redirect to="/"/>
                </Route>

                {/*<Route*/}
                {/*    path="/error"*/}
                {/*    component={() => (*/}
                {/*        <Error label="Une erreur est survenue, veuillez recharger la page."/>*/}
                {/*    )}*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path="/maintenance"*/}
                {/*    component={() => (*/}
                {/*        <Error*/}
                {/*            label="Une maintenance est en cours, veuillez réessayer plus tard."*/}
                {/*            btnLabel="Recharger"*/}
                {/*            reloadTimeout={300000}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*/>*/}

                <Route
                    path="*"
                    component={() => (
                        <Error
                            code={404}
                            label="La page demandée n'existe pas."
                        />
                    )}
                />
            </Switch>
        </Fragment>
    );
};

export default FrontendRoutes;
