import Day from "./Day";
import Event from "./Event";
import HalfDay from "./HalfDay";
import Overtime from "./Overtime";
import moment from "moment";
import "moment/locale/fr";

class Month {
    id;
    month;
    year;
    editable;
    submitted;
    submittable;
    autofillable;
    absenceValidated;
    mainActivity;
    noPrestation;
    specialTime;
    submittedAt;
    locked;
    user;
    files;
    days;
    halfdays;
    events;
    overtimes;
    canExtraActivities;
    canRequestExtraActivities;
    extraActivityWeeks;
    surveySent;
    extraActivities;

    constructor(json) {
        this.id = json.id;
        this.month = json.month;
        this.year = json.year;
        this.editable = json.editable;
        this.submitted = json.submitted;
        this.submittable = json.submittable;
        this.autofillable = json.autofillable;
        this.absenceValidated = json.absence_validated;
        this.mainActivity = json.main_activity;
        this.noPrestation = json.no_prestation;
        this.specialTime = json.special_time;
        this.submittedAt = json.submitted_at;
        this.locked = json.locked;
        this.user = json.user;
        this.files = json.files;
        this.days = [];
        this.halfdays = [];
        this.events = [];
        this.overtimes = [];
        this.canExtraActivities = json.can_extra_activities;
        this.canRequestExtraActivities = json.can_request_extra_activities;
        this.extraActivityWeeks = json.extra_activity_weeks;
        this.surveySent = json.survey_sent;
        this.extraActivities = json.extra_activities;

        json.days.map((day) => {
            this.days.push(new Day(day));
        });

        json.halfdays.map((halfday) => {
            this.halfdays.push(new HalfDay(halfday));
        });

        json.events.map((event) => {
            this.events.push(new Event(event));
        });

        json.overtimes.map((overtime) => {
            this.overtimes.push(new Overtime(overtime));
        });
    }

    get loaded() {
        return Boolean(this.id);
    }

    extraActivityExists(weekNumber) {
        return this.extraActivities.find(extra => extra.week == weekNumber)
    }

    hasExtraActivityPaymentChoice() {
        let duration = 0;

        this.extraActivities.map((week) => {
            let sundayOfWeek = moment().isoWeek(week.week).isoWeekday(7);
            if (parseInt(sundayOfWeek.format('M')) <= this.month) {
                duration += week.duration
            }
        })

        this.overtimes.map((overtime) => {
            if (overtime.type === Overtime.INTERVENTION) {
                overtime.days.map((day) => {
                    let sundayOfWeek = moment(day.date).isoWeekday(7)
                    if (parseInt(sundayOfWeek.format('M')) <= this.month) {
                        day.hours.map((hour) => {
                            duration += hour.duration
                        })
                    }
                })
            }
        })

        return duration > 0;
    }
}

export default Month;
