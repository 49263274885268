import React, {useEffect, Fragment, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import CalendarHeader from "components/TimesheetContainer/Calendar/CalendarHeader";
import DaysContainer from "components/TimesheetContainer/Calendar/DaysContainer";
import DaysOfWeek from "components/TimesheetContainer/Calendar/DaysOfWeek";
import Edit from "components/TimesheetContainer/Forms/Event/Edit";
import usePressEscapeKey from "hooks/usePressEscapeKey";

import * as TimesheetActions from "actions/Timesheet";
import {changeOverlayContent} from "events/OverlayEvents";
import SendMonthButton from "components/TimesheetContainer/Calendar/SendMonthButton";
import AskForExtraActivityButton from "components/TimesheetContainer/Calendar/AskForExtraActivityButton";
import SentAt from "components/TimesheetContainer/Calendar/SentAt";
import DownloadCRA from "./DownloadCRA";

import "./Calendar.scss";
import DepositSpecificCRAButton from "./DepositSpecificCRAButton";

const Calendar = (props) => {
    const dispatch = useDispatch();

    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );
    const selectedEndDay = useSelector(
        (state) => state.timesheet.selectedEndDay
    );
    const [modality, setModality] = useState(null);
    const [weeksCount, setWeeksCount] = useState(0);

    const {days, me, monthEntity, type, user, previousMonth} = props;

    const getWeeksInMonth = (year, month) => {
        let count = 0;
        if (typeof month !== "undefined") {
            let paddedMonth = month.toString().padStart(2, '0')
            let date = moment.parseZone(year + "-" + paddedMonth + '-01', 'YYYY-MM-DD').isoWeekday(1);

            count = 1;

            do {
                date.add(1, 'w')
                if (date.format('MM/YYYY') === paddedMonth+'/'+year) {
                    count++
                }
            } while (date.format('MM/YYYY') === paddedMonth+'/'+year);
        }

        return count
    }

    usePressEscapeKey(() => {
        if (selectedStartDay && !selectedEndDay) {
            dispatch(TimesheetActions.clearDaysSelection());
            dispatch(TimesheetActions.clearDaysHoveredSelection());
        }
    });

    useEffect(() => {
        setWeeksCount(getWeeksInMonth(monthEntity.month, monthEntity.year))
    }, [monthEntity])

    useEffect(() => {
        if (selectedEndDay) {
            let iterableDays = Object.values(days);
            let daysToAdd = [];
            iterableDays.forEach((element) => {
                if (element.worked) {
                    if (
                        moment.parseZone(element.date_at) >
                        selectedStartDay.date &&
                        moment.parseZone(element.date_at) < selectedEndDay.date
                    ) {
                        daysToAdd.push(element.id);
                    }
                }
            });
            dispatch(TimesheetActions.selectDays(daysToAdd));
            changeOverlayContent(
                <Edit action="new" monthEntity={monthEntity} modality={me.modality}/>
            );
        }
    }, [selectedEndDay]);

    useEffect(() => {
        let modalityNumber;
        if (Array.isArray(me)) {
            modalityNumber = me[0].modality;
        } else {
            modalityNumber = me.modality;
        }
        switch (modalityNumber) {
            case "1":
                setModality("35h");
                break;
            case "2":
                setModality("38h30");
                break;
            case "3":
                setModality("Forfait Jour");
                break;
            default:
                setModality("Inconnue");
                break;
        }
    }, [me])

    const handleClick = () => {
        changeOverlayContent(<DownloadCRA monthEntity={monthEntity}/>);
    };

    if (monthEntity.loaded) {
        return (
            <div>
                <div
                    className={
                        (type === "view" ? "view-only " : "") +
                        "calendar-container"
                    }
                >
                    <CalendarHeader
                        monthEntity={monthEntity}
                        type={type}
                        user={user}
                    />

                    {((!Array.isArray(me) && me.type !== 'contractor') || (Array.isArray(me) && me[0].type !== "contractor")) && <div className={"modality-container"}>
                        Modalité <span className={"modality-label"}>{modality}</span>
                    </div>}
                    <DaysOfWeek/>
                    <DaysContainer
                        type={type}
                        monthEntity={monthEntity}
                        days={days}
                        me={me}
                        modality={modality}
                        mainActivity={
                            monthEntity.mainActivity
                                ? monthEntity.mainActivity
                                : null
                        }
                        user={user}
                        weeksCount={weeksCount}
                    />


                    {!props.monthEntity.submitted ? (
                        type !== "view" && (<Fragment>
                                <SendMonthButton
                                    monthEntity={monthEntity}
                                    personType={me.type}
                                    hasEvents={monthEntity.events.length > 0}
                                    previousMonth={previousMonth}
                                />
                            </Fragment>
                        )
                    ) : (
                        <Fragment>
                            <SentAt submittedAt={monthEntity.submittedAt}/>
                            {type !== "view" &&
                                me.type === "consultant" &&
                                !monthEntity.locked && (
                                    <DepositSpecificCRAButton
                                        monthEntity={monthEntity}
                                    />
                                )}
                        </Fragment>
                    )}
                    {type !== "view" && monthEntity.events.length > 0 && (
                        <div className="download-cra" onClick={handleClick}>
                            Télécharger le CRA{" "}
                            <i className="far fa-arrow-to-bottom"></i>
                        </div>
                    )}

                    {monthEntity.canRequestExtraActivities &&
                        <AskForExtraActivityButton
                            monthEntity={monthEntity}
                        />
                    }

                </div>
            </div>
        );
    } else {
        return <div className="calendar-container"></div>;
    }
};

export default Calendar;
