import { schema } from "normalizr";

const userSchema = new schema.Entity("user");
const messageSchema = new schema.Entity("message");
const listUserSchema = new schema.Entity("listUser");
const runAsListUserSchema = new schema.Entity("runAsListUser");
const managersListSchema = new schema.Entity("managersList");
const halfDaySchema = new schema.Entity("halfdays");
const daySchema = new schema.Entity("days", {
    am: halfDaySchema,
    pm: halfDaySchema,
});
const eventCodeSchema = new schema.Entity("eventCodes");

const overtimeCodeSchema = new schema.Entity("overtimeCodes");

const eventSchema = new schema.Entity("events");

const overtimeSchema = new schema.Entity("overtimes");

const contactSchema = new schema.Entity(
    "contacts",
    {},
    {
        idAttribute: (value) => {
            return value.matricule ? value.matricule : value.id;
        },
    }
);
const consultingTeamSchema = new schema.Entity("consultingTeam");
const myContactSchema = new schema.Entity("mycontacts", {
    contacts: [contactSchema],
});
const boundingEventSchema = new schema.Entity(
    "boundingEvents",
    {},
    {
        idAttribute: () => {
            return "boundingEvents";
        },
    }
);
const absenceHistorySchema = new schema.Entity("absenceHistory");
// const last3AbsencesSchema = new schema.Entity("last3Absences");
const postSchema = new schema.Entity("posts");
const highlightSchema = new schema.Entity("highlight");
const internalNewsSchema = new schema.Entity("internalNews");
const iosAppTokenSchema = new schema.Entity("iosAppToken");
const periodLiteSchema = new schema.Entity(
    "periodLite",
    {},
    {
        idAttribute: (value) => {
            return "currentPeriod";
        },
    }
);
const monthSchema = new schema.Entity("month");
const previousMonthSchema = new schema.Entity("previousMonth");
const extraActivityMonthSchema = new schema.Entity("extraActivityMonths");
const extraActivityConfigSchema = new schema.Entity("extraActivityConfig",
    {},
    {
        idAttribute: () => {
            return "config";
        },
    });
const specificCraSchema = new schema.Entity("specificCra");

// Validation
const absenceForValidationSchema = new schema.Entity("absencesForValidation");
const eventForValidationSchema = new schema.Entity("eventsForValidation");
const monthForValidationSchema = new schema.Entity("monthsForValidation");
const periodSchema = new schema.Entity("periods");
const eventActionSchema = new schema.Entity("eventActions");
const monthActionSchema = new schema.Entity("monthActions");
const eventCodeForValidationSchema = new schema.Entity(
    "eventCodesForValidation"
);
const overtimeForValidationSchema = new schema.Entity("overtimesForValidation");
const extraActivitiesWeeksForValidationSchema = new schema.Entity("extraActivitiesWeeksForValidation");
const extraActivitiesConfigForValidationSchema = new schema.Entity("extraActivitiesConfigForValidation",
    {},
    {
        idAttribute: () => {
            return "config";
        },
    });
const fileSchema = new schema.Entity(
    "files",
    {},
    {
        idAttribute: (value) => {
            return value.url;
        },
    }
);

const socialSchema = new schema.Entity(
    "social",
    {},
    { idAttribute: () => "sections" }
);

const personalEmailSchema = new schema.Entity(
    "personalEmail",
    {},
    { idAttribute: () => "success" }
);
const tutorialSchema = new schema.Entity("tutorials");
const meHierarchySchema = new schema.Entity("meHierarchy");
const unionSchema = new schema.Entity("union");
const sectionsSchema = new schema.Entity(
    "sections",
    {},
    { idAttribute: () => "synchrone" }
);
const teamPlanningSchema = new schema.Entity("teamPlanning");
const extraActivityWeekSchema = new schema.Entity("extraActivityWeek")
const missingExtraActivityWeekSchema = new schema.Entity("missingExtraActivityWeek")

export const Schemas = {
    MESSAGE: messageSchema,
    USER: userSchema,
    USER_ARRAY: [userSchema],
    LIST_USER: listUserSchema,
    LIST_USER_ARRAY: [listUserSchema],
    RUN_AS_LIST_USER: runAsListUserSchema,
    RUN_AS_LIST_USER_ARRAY: [runAsListUserSchema],
    MANAGERS_LIST: managersListSchema,
    MANAGERS_LIST_ARRAY: [managersListSchema],
    ABSENCES_FOR_VALIDATION: absenceForValidationSchema,
    ABSENCES_FOR_VALIDATION_ARRAY: [absenceForValidationSchema],
    EVENTS_FOR_VALIDATION: eventForValidationSchema,
    EVENTS_FOR_VALIDATION_ARRAY: [eventForValidationSchema],
    MONTHS_FOR_VALIDATION: monthForValidationSchema,
    MONTHS_FOR_VALIDATION_ARRAY: [monthForValidationSchema],
    EVENT_CODES_FOR_VALIDATION: eventCodeForValidationSchema,
    EVENT_CODES_FOR_VALIDATION_ARRAY: [eventCodeForValidationSchema],
    OVERTIMES_FOR_VALIDATION: overtimeForValidationSchema,
    OVERTIMES_FOR_VALIDATION_ARRAY: [overtimeForValidationSchema],
    EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION: extraActivitiesWeeksForValidationSchema,
    EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_ARRAY: [extraActivitiesWeeksForValidationSchema],
    EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION: extraActivitiesConfigForValidationSchema,
    EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_ARRAY: [extraActivitiesConfigForValidationSchema],
    MONTH: monthSchema,
    PREVIOUS_MONTH: previousMonthSchema,
    EXTRA_ACTIVITY_MONTH: extraActivityMonthSchema,
    EXTRA_ACTIVITY_CONFIG: extraActivityConfigSchema,
    EXTRA_ACTIVITY_MONTH_ARRAY: [extraActivityMonthSchema],
    DAY: daySchema,
    DAY_ARRAY: [daySchema],
    HALFDAY: halfDaySchema,
    HALFDAY_ARRAY: [halfDaySchema],
    EVENT: eventSchema,
    EVENT_ARRAY: [eventSchema],
    BOUNDING_EVENT_ARRAY: boundingEventSchema,
    ABSENCE_HISTORY_ARRAY: [absenceHistorySchema],
    // LAST_3_ABSENCES_ARRAY: [last3AbsencesSchema],
    EVENTCODE: eventCodeSchema,
    EVENTCODE_ARRAY: [eventCodeSchema],
    OVERTIME: overtimeSchema,
    OVERTIME_ARRAY: [overtimeSchema],
    OVERTIMECODE: overtimeCodeSchema,
    OVERTIMECODE_ARRAY: [overtimeCodeSchema],
    CONTACT: contactSchema,
    CONTACT_ARRAY: [contactSchema],
    CONSULTING_MEMBER: consultingTeamSchema,
    CONSULTING_TEAM: [consultingTeamSchema],
    MY_CONTACT: myContactSchema,
    MY_CONTACT_ARRAY: [myContactSchema],
    POST: postSchema,
    POST_ARRAY: [postSchema],
    HIGHLIGHT: [highlightSchema],
    PERIOD: periodSchema,
    PERIOD_ARRAY: [periodSchema],
    PERIOD_LITE: periodLiteSchema,
    EVENT_ACTION: eventActionSchema,
    EVENT_ACTION_ARRAY: [eventActionSchema],
    MONTH_ACTION: monthActionSchema,
    MONTH_ACTION_ARRAY: [monthActionSchema],
    FILE: fileSchema,
    TUTORIAL: tutorialSchema,
    TUTORIAL_ARRAY: [tutorialSchema],
    IOS_APP_TOKEN: iosAppTokenSchema,
    ME_HIERARCHY: meHierarchySchema,
    PERSONAL_EMAIL: personalEmailSchema,
    UNION: unionSchema,
    UNION_ARRAY: [unionSchema],
    SECTIONS: sectionsSchema,
    SPECIFIC_CRA: specificCraSchema,
    SPECIFIC_CRA_ARRAY: [specificCraSchema],
    TEAM_PLANNING: teamPlanningSchema,
    TEAM_PLANNING_ARRAY: [teamPlanningSchema],
    SOCIAL: socialSchema,
    SOCIAL_ARRAY: [socialSchema],
    INTERNAL_NEWS: internalNewsSchema,
    EXTRA_ACTIVITY_WEEK: extraActivityWeekSchema,
    MISSING_EXTRA_ACTIVITY_WEEK: missingExtraActivityWeekSchema
};
