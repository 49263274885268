class Event {
    id;
    eventCode;
    autoGenerated;
    commentCount;
    backwarded;
    state;
    startAt;
    endAt;
    startMoment;
    endMoment;
    locked;
    remoteWorking;
    daysCount;
    createdAt;
    updatedAt;
    user;
    editable;
    validated1At;
    validated1ByName;

    constructor(json) {
        this.id = json.id;
        this.eventCode = json.event_code;
        this.autoGenerated = json.auto_generated;
        this.commentCount = json.comment_count;
        this.backwarded = json.backwarded;
        this.state = json.state;
        this.startAt = json.start_at;
        this.endAt = json.end_at;
        this.startMoment = json.start_moment;
        this.endMoment = json.end_moment;
        this.locked = json.locked;
        this.remoteWorking = json.remote_working;
        this.daysCount = json.days_count;
        this.createdAt = json.created_at;
        this.updatedAt = json.updated_at;
        this.user = json.user;
        this.editable = json.editable;
        this.validated1At = json.validated1_at;
        this.validated1ByName = json.validated1_by_name;
    }
}

export default Event;
