import React, { useState } from "react";
import Lightbox from "components/common/Lightbox";
import { changeOverlayContent } from "events/OverlayEvents";
import * as ValidationActions from "actions/Validation";
import { useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import "./AbsenceValidationForm.scss";
import { useEffect } from "react";
import EventCode from "entities/EventCode";
import Select from "react-select";
import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";

const AbsenceValidationForm = (props) => {
    const [newEventCodesForValidation, setNewEventCodesForValidation] =
        useState([]);
    const {
        event,
        eventCodeDescription,
        user,
        manager,
        validatedState,
        action,
        loadList,
    } = props;
    const [selectedNewEventCode, setSelectedNewEventCode] = useState(
        event.eventCode
    );
    const [submitting, setSubmitting] = useState(false);
    const [comment, setComment] = useState("");
    const dispatch = useDispatch();

    const getRecipient = () => {
        let recipient = "";

        switch (action) {
            case "backward":
            case "refuse":
                recipient = "(envoyé par mail au collaborateur)";
                break;

            case "validate":
            case "edit-type":
                recipient = "(interne)";
                break;

            default:
                break;
        }

        return recipient;
    };

    const [sendTo, setSendTo] = useState(getRecipient());

    useEffect(() => {
        if (action === "edit-type") {
            dispatch(
                ValidationActions.fetchEventCodesForEditAction(event.id)
            ).then((json) => {
                let tempEventCodes = [];
                Object.values(json.response.entities.eventCodes).map(
                    (element) => {
                        if (element.type === "absence") {
                            tempEventCodes.push(new EventCode(element));
                        }
                    }
                );
                tempEventCodes.sort((a, b) => {
                    return a.description.localeCompare(b.description);
                });
                setNewEventCodesForValidation(tempEventCodes);
            });
        }
    }, []);

    const eventDescription = () => {
        let description = " ";
        if (event.startAt === event.endAt) {
            description += `du ${moment
                .parseZone(event.startAt)
                .format("DD/MM")}`;
            if (event.daysCount === 0.5) {
                if (event.startMoment === "am") {
                    description += " matin";
                } else if (event.startMoment === "pm") {
                    description += " après-midi";
                }
            }
        } else {
            description += `du ${moment
                .parseZone(event.startAt)
                .format("DD/MM")}`;
            if (event.startMoment === "pm") {
                description += " midi";
            }
            description += ` au ${moment
                .parseZone(event.endAt)
                .format("DD/MM")}`;
            if (event.endMoment === "am") {
                description += " midi";
            }
        }
        return description;
    };

    const lightboxTitle = () => {
        let title = "";
        switch (action) {
            case "validate":
                title = "Valider la demande d'absence";
                break;
            case "refuse":
                title = "Refuser la demande d'absence";
                break;
            case "backward":
                title = "Redonner la main au collaborateur";
                break;
            case "edit-type":
                title = "Modifier le type d'absence";
                break;
            default:
                break;
        }

        return title;
    };

    const close = () => {
        changeOverlayContent(null);
    };

    const handlePost = () => {
        if (!submitting) {
            setSubmitting(true);
            let json = {
                event: event.id,
                comment: comment,
                updated_at: moment
                    .parseZone(event.updatedAt)
                    .format("YYYY-MM-DD[T]HH:mm:ss"),
            };

            if (action === "validate") {
                json.action = validatedState;
            } else if (action === "refuse") {
                json.action = "refused";
            } else if (action === "backward") {
                json.action = "submitted";
            }

            let body = JSON.stringify(json);
            dispatch(ValidationActions.postEventAction(body)).then(() => {
                changeOverlayContent(null);
                setSubmitting(false);
                loadList();
            });
        }
    };

    const handlePatch = () => {
        if (!submitting) {
            setSubmitting(true);
            let json = {
                comment: comment,
                event_code: selectedNewEventCode,
            };
            let body = JSON.stringify(json);
            dispatch(
                ValidationActions.changeAbsenceTypeAction(event.id, body)
            ).then(() => {
                changeOverlayContent(null);
                setSubmitting(false);
                loadList();
            });
        }
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    return (
        <Lightbox className="lb-centered validate-absence-lb">
            <div className="validate-absence-header">
                <div className="validate-absence-title">{lightboxTitle()}</div>
                <div className="validate-absence-identity-block">
                    <div className="validate-absence-identity">
                        {user.identity}
                    </div>
                    <PersonTypeBadge personType={user.type} />
                    <div className="validate-absence-matricule">
                        {user.matricule}
                    </div>
                    <div className="validate-absence-manager">
                        Responsable : {manager && manager.identity}
                    </div>
                </div>
            </div>
            <div className="validate-absence-description">
                {eventCodeDescription + eventDescription()}
            </div>
            {action === "edit-type" && (
                <Select
                    className="select-container-edit-type"
                    classNamePrefix="select-container-edit-type"
                    name="period"
                    value={
                        newEventCodesForValidation
                            ? newEventCodesForValidation.find((option) => {
                                  return option.id === selectedNewEventCode;
                              })
                            : ""
                    }
                    isOptionSelected={(selOpt) => {
                        return selOpt === selectedNewEventCode;
                    }}
                    options={newEventCodesForValidation}
                    onChange={(option) => setSelectedNewEventCode(option.id)}
                    placeholder="Veuillez sélectionner un autre libellé d'absence"
                />
            )}
            <div className="validate-absence-comment">
                <div className="validate-absence-comment-label">
                    <i className="far fa-comment-alt"></i>
                    Commentaire :{" "}
                    <span className="send-to-comment">{sendTo}</span>
                </div>
                <textarea
                    className="validate-absence-comment-input"
                    onChange={(e) => handleCommentChange(e)}
                ></textarea>
            </div>

            <div className="validate-absence-actions">
                <div className="cancel-btn" onClick={close}>
                    Annuler
                </div>
                {action === "refuse" && (
                    <div className="refuse-btn" onClick={handlePost}>
                        Refuser
                    </div>
                )}
                {(action === "validate" || action === "backward") && (
                    <div className="next-btn" onClick={handlePost}>
                        Confirmer
                    </div>
                )}
                {action === "edit-type" && (
                    <div className="next-btn" onClick={handlePatch}>
                        Confirmer
                    </div>
                )}
            </div>
        </Lightbox>
    );
};

export default AbsenceValidationForm;
