import React, {useEffect, useState} from "react";
import {changeOverlayContent} from "events/OverlayEvents";
import {API_ROOT} from "config/Parameters";
import ReactPlayer from "react-player";
import "./NewGTAPopUp.scss";

const NewGTAPopUp = (props) => {
    const [clickableVideoClose, setClickableVideoClose] = useState(false);
    const { setPopupVisible, userType } = props;

    const close = () => {
        localStorage.setItem("newGTAPopUpHidden", true);
        changeOverlayContent(null);
        setPopupVisible(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setClickableVideoClose(true);
        }, 100);
    }, []);

    return (
        <div id="video-gta-popup">
            <div
                id="video-gta-popup-close"
                className={clickableVideoClose ? "" : "hidden"}
                onClick={close}
            ></div>
            <ReactPlayer
                onContextMenu={(e) => {
                    e.preventDefault();
                    return false;
                }}
                url={userType === "contractor" ? "https://youtu.be/hQ8VKHhWk1M" : "https://youtu.be/YVbfLykruJ0"}
                controls={true}
                config={{
                    file: {
                        attributes: {
                            controlsList: "nodownload",
                        },
                    },
                }}
                playing={true}
                muted={true}
                width="722px"
                height="407px"
                style={{
                    margin: "0 auto",
                    border: "1px solid #D8D8D8",
                    backgroundColor: "#D8D8D8",
                }}
            />
        </div>
    );
};

export default NewGTAPopUp;
