import React from "react";

import ConfirmSendMonth from "components/TimesheetContainer/Calendar/ConfirmSendMonth";
import { changeOverlayContent } from "events/OverlayEvents";

import "./SendMonthButton.scss";

const SendMonthButton = (props) => {
    const { monthEntity, personType, hasEvents, previousMonth } = props;
    const isActive = monthEntity.submittable && !monthEntity.submitted;

    const handleClick = () => {
        if (isActive) {
            changeOverlayContent(
                <ConfirmSendMonth
                    month={monthEntity.month}
                    year={monthEntity.year}
                    personType={personType}
                    hasEvents={hasEvents}
                    surveySent={monthEntity.surveySent}
                    hasExtraActivityPaymentChoice={() => monthEntity.hasExtraActivityPaymentChoice()}
                    extraActivities={monthEntity.extraActivities}
                    extraActivityWeeks={monthEntity.extraActivityWeeks}
                    overtimes={monthEntity.overtimes}
                    previousMonth={previousMonth}
                />
            );
        }
    };

    return (
        <div
            onClick={handleClick}
            className={"send-month-btn" + (isActive ? " active" : "")}
            title={
                !monthEntity.absenceValidated
                    ? "Des absences n'ont pas encore été validées par votre manager"
                    : ""
            }
        >
            Envoyer mon CRA
        </div>
    );
};

export default SendMonthButton;
