import React from "react";

import {changeOverlayContent} from "events/OverlayEvents";

import "./AskForExtraActivityButton.scss";
import ConfirmAskForExtraActivity from "components/TimesheetContainer/Calendar/ConfirmAskForExtraActivity";

const AskForExtraActivityButton = (props) => {
    const {monthEntity} = props;

    const handleClick = () => {
        changeOverlayContent(<ConfirmAskForExtraActivity monthEntity={monthEntity} />)
    }

    return <div
        onClick={handleClick}
        className={"ask-extra-activity-btn"}
    >
        Demander la saisie d'une activité complémentaire
    </div>
}

export default AskForExtraActivityButton;