import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import Lightbox from "components/common/Lightbox";
import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";

import * as ValidationActions from "actions/Validation";

import { changeOverlayContent } from "events/OverlayEvents";

import "./ActivityValidationForm.scss";

const ActivityValidationForm = (props) => {
    const {
        action,
        user,
        manager,
        month,
        validatedState,
        period,
        loadList,
    } = props;
    const [comment, setComment] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    let activityCount = 0,
        absenceCount = 0;

    Object.values(month.events).map((event, key) => {
        if (event.days > 0) {
            if (event.type === "absence") {
                absenceCount += event.days;
            } else {
                activityCount += event.days;
            }
        }
    });

    const lightboxTitle = () => {
        let title = "";
        switch (action) {
            case "validate":
                title = "Valider le CRA";
                break;
            case "backward":
                title = "Redonner la main au collaborateur";
                break;
            default:
                break;
        }

        return title;
    };

    const close = () => {
        changeOverlayContent(null);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handlePost = () => {
        if (!submitting) {
            setSubmitting(true);
            let json = {
                month: month.id,
                comment: comment,
                updated_at: moment.parseZone(month.updatedAt).format(
                    "YYYY-MM-DD[T]HH:mm:ss"
                ),
            };

            if (action === "validate") {
                json.action = validatedState;
            } else if (action === "backward") {
                json.action = "non_submitted";
            }

            let body = JSON.stringify(json);
            dispatch(ValidationActions.postMonthAction(body)).then(() => {
                changeOverlayContent(null);
                setSubmitting(false);
                loadList();
            });
        }
    };

    return (
        <Lightbox className="lb-centered validate-activity-lb">
            <div className="validate-activity-header">
                <div className="validate-activity-title">{lightboxTitle()}</div>
                <div className="validate-activity-identity-block">
                    <div className="validate-activity-identity">
                        {user.identity}
                    </div>
                    <PersonTypeBadge personType={user.type} />
                    <div className="validate-activity-matricule">
                        {user.matricule}
                    </div>
                    <div className="validate-activity-manager">
                        Responsable : {manager && manager.identity}
                    </div>
                </div>
            </div>

            <div className="validate-activity-period">
                {period.format("MMMM YYYY")}
            </div>

            <div className="validate-activity-counts">
                <div className="activity-count">
                    Activités : <span className="count">{activityCount} J</span>
                </div>
                <div className="absence-count">
                    Absences : <span className="count">{absenceCount} J</span>
                </div>
                <div className="total-count-information">
                    Total :{" "}
                    <div className="total-count">
                        {absenceCount + activityCount} J
                    </div>
                </div>
            </div>

            {month.noPrestation && user.isContractor && (
                <div className="validate-form-no-prestation">
                    Aucune prestation ce mois-ci
                </div>
            )}

            <div className="validate-activity-particularities-container">
                {(month.constraint || month.intervention) && (
                    <div className="validate-activity-particularity">
                        <i className="fal fa-fw fa-watch icon"></i>
                        <div className="label">Activités complémentaires</div>
                    </div>
                )}
                {month.remoteWorking && (
                    <div className="validate-activity-particularity">
                        <i className="fas fa-fw fa-home-lg-alt icon"></i>
                        <div className="label">Télétravail</div>
                    </div>
                )}
                {month.specialTime && (
                    <div className="validate-activity-particularity">
                        <i className="fal fa-fw fa-hourglass icon"></i>
                        <div className="label">Temps spécial</div>
                    </div>
                )}
                {month.partialTime && user.type !== "contractor" && (
                    <div className="validate-activity-particularity">
                        <i className="fal fa-fw fa-percent icon"></i>
                        <div className="label">Temps partiel</div>
                    </div>
                )}
                {user.type === "contractor" && (
                    <div className="validate-activity-particularity">
                        <i className="far fa-fw fa-address-book icon"></i>
                        <div className="label">Prestataire</div>
                    </div>
                )}
            </div>

            {action === "validate" && month.comment && (
                <div className="validate-activity-user-comment-container">
                    <i className="fal fa-quote-left"></i>
                    <div className="validate-activity-user-comment">
                        {month.comment}
                    </div>
                    <i className="fal fa-quote-right"></i>
                </div>
            )}

            <div className="validate-activity-comment">
                <div className="validate-activity-comment-label">
                    <i className="far fa-comment-alt"></i>
                    Commentaire {action === "validate" && "de validation "}:
                </div>
                <textarea
                    className="validate-activity-comment-input"
                    onChange={(e) => handleCommentChange(e)}
                ></textarea>
            </div>

            <div className="validate-activity-actions">
                <div className="cancel-btn" onClick={close}>
                    Annuler
                </div>
                {(action === "validate" || action === "backward") && (
                    <div className="next-btn" onClick={handlePost}>
                        Confirmer
                    </div>
                )}
            </div>
        </Lightbox>
    );
};

export default ActivityValidationForm;
