import React, {Fragment, useEffect, useState} from "react";
import moment from "moment";
import "moment/locale/fr";

import {EntityRepository, MANAGER_REPOSITORY} from "store/EntityRepository";
import {changeOverlayContent} from "events/OverlayEvents";

import PersonTypeBadge from "../common/PersonTypeBadge";
import ModalityBadge from "../common/ModalityBadge";
import "../Block/IdentityBlock.scss";
import "./ExtraActivityRow.scss";
import ExtraActivitiesValidationForm from "./ExtraActivitiesValidationForm";

const ExtraActivityRow = (props) => {
    const {row, me, loadList} = props
    const managerRepo = EntityRepository().getRepository(MANAGER_REPOSITORY);

    const user = row.months[0].user;
    const getManager = () => {
        if (managerRepo.findByMatricule(user.manager)) {
            return managerRepo.findByMatricule(user.manager);
        } else if (user.manager === me.matricule) {
            return me;
        }
        return;
    };
    const manager = getManager();

    return <div className={"person-row"}>
        <div className="identity-block">
            <div className="identity-block-identity">{user.identity}</div>
            <div className="badges">
                <PersonTypeBadge personType={user.type}/>
                {user.modality > 0 && <ModalityBadge modality={user.modality}/>}
            </div>
            <div className="identity-block-matricule">Matricule : {user.matricule}</div>
            {manager && (
                <div className="identity-block-manager">
                    Responsable : {manager.identity}
                </div>
            )}
        </div>
        <div className="extra-activities-request-at-block">
            {row.months.map((month, key) => {
                return <div className={"extra-activities-month-line"}
                            key={key}>{moment(month.requestActivityAt).format('DD/MM/YYYY')}</div>
            })}
        </div>
        <div className="extra-activities-months-block">
            {row.months.map((month, key) => {
                return <div className={"extra-activities-month-line"}
                            key={key}>{moment(month.year + "-" + month.month.toString().padStart(2, '0') + "-01").format('MMMM YYYY')}</div>
            })}
        </div>
        <div className="extra-activities-actions-block">
            {row.months.map((month, key) => {
                return (
                    <div key={key}>
                        {month.extraActivitiesStatus !== 'refused' &&
                            <i title={month.extraActivitiesStatus === 'pending' ? "Valider la demande" : "Demande validée"}
                               className={"far fa-check-circle action-btn" + (month.extraActivitiesStatus === 'accepted' ? ' green-btn' : '')}
                               onClick={() => {
                                   if (month.extraActivitiesStatus === 'pending') {
                                       changeOverlayContent(
                                           <ExtraActivitiesValidationForm
                                               action="validate"
                                               user={user}
                                               month={month}
                                               loadList={loadList}
                                           />
                                       )
                                   }
                               }}
                            ></i>}

                        {month.extraActivitiesStatus !== 'accepted' &&
                            <i title={month.extraActivitiesStatus === 'pending' ? "Refuser la demande" : "Demande refusée"}
                               className={"far fa-times-circle action-btn" + (month.extraActivitiesStatus === 'refused' ? ' coral-btn' : '')}
                               onClick={() => {
                                   if (month.extraActivitiesStatus === 'pending') {
                                       changeOverlayContent(
                                           <ExtraActivitiesValidationForm
                                               action="refuse"
                                               user={user}
                                               month={month}
                                               loadList={loadList}
                                           />
                                       )
                                   }
                               }}
                            ></i>}
                    </div>)
            })}

        </div>
    </div>
}

export default ExtraActivityRow;